import React, { useEffect, useState } from 'react';
import Header from "../components/Header";
import { BrowserRouter } from "react-router-dom";
import Allroutes from "./Allroutes";
import axios from "axios";

const Index = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Define your AWS API endpoint URL
    const apiUrl = 'https://q205s7a2ql.execute-api.eu-north-1.amazonaws.com/TEST/inventory';

    // Set up AWS authentication headers (replace with your AWS credentials)
    const awsHeaders = {
      'x-api-key': 'YyTQxvrQK49JTETmpVi6i2Nrw9rhduhY7rpW5nLl', // or 'Authorization': 'Bearer YOUR_ACCESS_TOKEN' for other authentication methods
    };

    // Make the Axios GET request
    axios.get(apiUrl, { headers: awsHeaders })
      .then((response) => {
        setData(response.data); // Assuming the response contains JSON data
      })
      .catch((error) => {
        console.error('Error making AWS API call:', error);
      });
  }, []);
  return (
    <BrowserRouter>
      <Allroutes />
    </BrowserRouter>
  );
};

export default Index;
