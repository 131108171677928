import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setActiveItem } from "../../app/reducers/activePage";

const PagesNav = ({}) => {
  // const [activepage, setActivepage] = useState("Our Projects");

  const activepage = useSelector((state) => state.activepage);
  const dispatch = useDispatch();
  const pages = [
    {
      name: "Our Projects",
      url: "/buy",
    },
    {
      name: "Inventory",
      url: "/rent",
    },
    {
      name: "Delivered Proj",
      url: "/sold",
    },
  ];

  return (
    <div className="py-8">
      <ul className="flex mx-auto w-full justify-center items-center  bg-[#eef2f6] max-w-[350px]   md:max-w-[450px] h-[50px]  ">
        {pages?.map((item, index) => {
          const isActive = activepage === item.name;

          return (
            <li
              key={index}
              className={`cursor-pointer transition-all duration-300 ease-in ${
                isActive
                  ? "bg-[#009932] text-white  rounded-[5px]"
                  : "text-[#009932]"
              }`}
              onClick={() => {
                dispatch(setActiveItem(item.name));
                console.log(item.name)
              }}
            >
              <button className="text-[14px]  cursor-pointer w-[120px] p-3   md:w-[150px]    px-4 whitespace-nowrap ">
                {item.name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PagesNav;
