import React from "react";
import Home from "../pages/Home";
import { Routes, Route } from "react-router-dom";
import Cominsoon from "../components/Cominsoon";

const Allroutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/" element={<Cominsoon />} /> */}

        
      </Routes>
    </>
  );
};

export default Allroutes;
