import React from "react";
import Header from "../components/Header";
import Slider from "../components/Slider";
import PagesNav from "./home/PagesNav";
import HouseContainer from "../components/HouseContainer";
import ProjectsContainer from "../components/ProjectsContainer";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Animatedsection from "../components/Animatedsection";
import Topbar from "../components/Topbar";
import Herosection from "../components/Herosection";
const Home = () => {
  const activepage = useSelector((state) => state.activepage);

  const propertyDataArray = [
    {
      name: "Dha Phase 6 Block J basement ",
      location: "Dha Phase 6 Block J",
      area: "1 kanal",
      demand: "187,500,000",
      image: "/assets/images/basementdhaphase6.jpeg",
      features: [
        "Full Basement",
        "5 bedrooms",
        "Six bathrooms",
        "Spacious garden",
        "Modern kitchen",
        "Garage for two cars",
      ],
      status: "For Sale",
    },

    {
      name: "Dha Phase 6 Block J basement ",
      location: "Another Location",
      area: "2 kanal",
      demand: "300,000,000",
      image: "/assets/images/img2.jpeg",
      features: [
        "Swimming Pool",
        "6 bedrooms",
        "Seven bathrooms",
        "Large backyard",
        "Home theater",
        "Three-car garage",
      ],
      status: "For Sale",
    },

    {
      name: "Dha Phase 6 Block J basement ",
      location: "Sample Location 3",
      area: "0.5 kanal",
      demand: "100,000,000",
      image: "/assets/images/img3.jpeg",
      features: [
        "Spacious living room",
        "4 bedrooms",
        "Four bathrooms",
        "Walk-in closet",
        "Patio",
        "Two-car garage",
      ],
      status: "For Sale",
    },

    {
      name: "Dha Phase 6 Block J basement ",
      location: "Another Location 4",
      area: "3 kanal",
      demand: "500,000,000",
      image: "/assets/images/img4.jpeg",
      features: [
        "Private pool",
        "7 bedrooms",
        "Eight bathrooms",
        "Lawn",
        "Library",
        "Four-car garage",
      ],
      status: "For Sale",
    },

    {
      name: "Dha Phase 6 Block J basement ",
      location: "Sample Location 5",
      area: "1.5 kanal",
      demand: "250,000,000",
      image: "/assets/images/img5.jpeg",
      features: [
        "Jacuzzi",
        "5 bedrooms",
        "Six bathrooms",
        "Outdoor kitchen",
        "Home office",
        "Carport",
      ],
      status: "For Sale",
    },

    {
      name: "Dha Phase 6 Block J basement ",
      location: "Sample Location 5",
      area: "1.5 kanal",
      demand: "250,000,000",
      image: "/assets/images/img6.jpeg",
      features: [
        "Jacuzzi",
        "5 bedrooms",
        "Six bathrooms",
        "Outdoor kitchen",
        "Home office",
        "Carport",
      ],
      status: "For Sale",
    },
  ];

  const projectsDataArray = [
    {
      name: "Dha Phase 6 Block J basement ",
      location: "Another Location 4",
      area: "3 kanal",
      image: "/assets/images/DH.jpg",
      features: [
        "Private pool",
        "7 bedrooms",
        "Eight bathrooms",
        "Lawn",
        "Library",
        "Four-car garage",
      ],
    },

    {
      name: "Dha Phase 6 Block J basement ",
      location: "Sample Location 5",
      area: "1.5 kanal",
      image: "/assets/images/etihad.jpg",
      features: [
        "Jacuzzi",
        "5 bedrooms",
        "Six bathrooms",
        "Outdoor kitchen",
        "Home office",
        "Carport",
      ],
    },

    {
      name: "Dha Phase 6 Block J basement ",
      location: "Sample Location 5",
      area: "1.5 kanal",
      image: "/assets/images/KT.jpg",
      features: [
        "Jacuzzi",
        "5 bedrooms",
        "Six bathrooms",
        "Outdoor kitchen",
        "Home office",
        "Carport",
      ],
    },
    {
      name: "Dha Phase 6 Block J basement ",
      location: "Dha Phase 6 Block J",
      area: "1 kanal",
      image: "/assets/images/lake-city.jpg",
      features: [
        "Full Basement",
        "5 bedrooms",
        "Six bathrooms",
        "Spacious garden",
        "Modern kitchen",
        "Garage for two cars",
      ],
    },

    {
      name: "Dha Phase 6 Block J basement ",
      location: "Another Location",
      area: "2 kanal",
      image: "/assets/images/NLC.jpg",
      features: [
        "Swimming Pool",
        "6 bedrooms",
        "Seven bathrooms",
        "Large backyard",
        "Home theater",
        "Three-car garage",
      ],
    },

    {
      name: "Dha Phase 6 Block J basement ",
      location: "Sample Location 3",
      area: "0.5 kanal",
      image: "/assets/images/Serena-1.jpg",
      features: [
        "Spacious living room",
        "4 bedrooms",
        "Four bathrooms",
        "Walk-in closet",
        "Patio",
        "Two-car garage",
      ],
    },
    {
      name: "Dha Phase 6 Block J basement ",
      location: "Sample Location 3",
      area: "0.5 kanal",
      image: "/assets/images/ZC.jpg",
      features: [
        "Spacious living room",
        "4 bedrooms",
        "Four bathrooms",
        "Walk-in closet",
        "Patio",
        "Two-car garage",
      ],
    },
    {
      name: "Dha Phase 6 Block J basement ",
      location: "Sample Location 3",
      area: "0.5 kanal",
      image: "/assets/images/Bahria.jpg",
      features: [
        "Spacious living room",
        "4 bedrooms",
        "Four bathrooms",
        "Walk-in closet",
        "Patio",
        "Two-car garage",
      ],
    },
  ];

  return (
    <div className="w-full bg-white">
      <img
        src="/assets/images/comingsoon.png"
        alt=""
        className="h-full w-full rounded-xl border shadow-md "
      />
      {/* <div className="">
        <Topbar />
        <Header />
        <Herosection />
      </div> */}
      {/* <div className=" py-8 max-h-[450px] overflow-hidden relative">
        <div className="">
          <Slider />
        </div>
        <img
          src="/assets/images/bgGreen.webp"
          alt=""
          className="absolute top-0 "
        />
        <div className="bg-black opacity-50 absolute top-0  w-full h-full"></div>
      </div> */}
      {/* <div className="max-w-[1480px] mx-auto">
        <PagesNav />
        {activepage == "Our Projects" ? (
          <ProjectsContainer projectsDataArray={projectsDataArray} />
        ) : activepage == "Inventory" ? (
          <HouseContainer propertyDataArray={propertyDataArray} />
        ) : (
          <ProjectsContainer projectsDataArray={projectsDataArray} />
        )}
      </div>
      <div>
        <Animatedsection/>
      </div> */}
    </div>
  );
};

export default Home;
