import { configureStore } from "@reduxjs/toolkit";
import brandDataReducer from "./reducers/brandDataSlice";
import activePage from "./reducers/activePage";
import favouriteProduct from "./reducers/favouriteProduct";
const store = configureStore({
  reducer: {
    brandsData: brandDataReducer,
    activepage: activePage,
    favouriteproduct: favouriteProduct,
  },
});

export default store;
